<template>
  <ListItem :condensed="true" :actions="actions">
    <div class="w-full flex justify-center items-center">
      <ThreeWayApproval
        v-if="showApprovalBox && approval"
        :item="item"
        :approval="approval"
        user="client"
      />
      <div class="flex items-center justify-between w-full">
        <div v-if="item.asAssemblyPath" class="min-w-64">
          <InfoSection :border="false">
            <template #above>
              {{ $f.truncate(itemPath, 45) }}
            </template>
            {{ $f.truncate(item.cost_type_name || item.item_name || item.cost_item_name, 40) }}
          </InfoSection>
        </div>
        <div class="ml-12">
          <InfoSection>
            <template #above> Due date </template>
            {{ formatDate(item.start_date) }} → {{ formatDate(item.end_date) }}
          </InfoSection>
        </div>
        <div class="ml-8">
          <Dropdown
            v-model="status"
            @change="handleSelectStatus"
            :options="taskStatuses"
            optionLabel="name"
            placeholder="Not started"
            :pt="{
              input: '!p-0',
              item: '!px-1.5 !py-1',
              list: '!py-0.5',
              trigger: 'absolute right-0 my-auto top-0 bottom-0 w-8'
            }"
            :ptOptions="{ mergeProps: true }"
          >
            <template #value="{ value }">
              <Tag
                v-if="value && value.name"
                :value="value.name"
                :pt="{
                  root: `w-full px-3 py-1.5 border-none pr-8 ${value.tagClasses}`
                }"
                :ptOptions="{ mergeProps: true }"
                :severity="value.severity"
              ></Tag>
              <Tag
                severity="secondary"
                :pt="{
                  root: 'w-full px-2 py-1 border-none text-pitch-black bg-cool-gray-200'
                }"
                :ptOptions="{ mergeProps: true }"
                v-else
                value="Not started"
              ></Tag>
            </template>
            <template #option="{ option }">
              <Tag
                :severity="option.severity"
                :value="option.name"
                :pt="{
                  root: `w-full px-3 py-1.5 border-none ${option.tagClasses}`
                }"
                :ptOptions="{ mergeProps: true }"
              ></Tag>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #right> </template>
    <template v-if="showFooter" #footer>
      <slot name="footer" />
    </template>
  </ListItem>
</template>

<script setup>
import { ref, computed, useSlots, defineProps, onMounted, onBeforeUnmount, toRef } from 'vue'
import moment from 'moment'
import Dropdown from 'primevue/dropdown'
import Tag from 'primevue/tag'
import { useStore } from 'vuex'
import useApproval from '@/components/composables/Approval'
import useTranslate from '@/components/composables/Translation'
import useTask from '@/components/schedule/Task'
import InfoSection from '../InfoSection.vue'
import ListItem from './ListItem.vue'
import ThreeWayApproval from '../../progress/approval/ThreeWayApproval.vue'
import { getApprovalsByItemAndApprover } from '@/apollo-client/requests/approval'
import eventBus from '@/eventBus'
Dropdown.compatConfig = { MODE: 3 }

const props = defineProps({
  item: {
    required: true
  },
  showApprovalBox: {
    default: true
  }
})

const slots = useSlots()
const store = useStore()
const { processAction, isApprovedByApprover, isFirstStepOfApproval, approvalStatuses } =
  useApproval()
const { l } = useTranslate()

const { statuses, taskStatuses, saveItemField } = useTask()

const item = toRef(props, 'item')
const approval = ref()
const showFooter = computed(() => slots.footer && slots.footer().length > 0)
const status = ref(statuses.value.find((i) => i.status === (item.value.item_status || 'p')))

const formatDate = (itemDate) => {
  return moment(itemDate * 1000).format('MMM, D')
}

const handleDecline = () => {
  return processAction(approval.value, approvalStatuses.DECLINED)
}

const itemPath = item.value.asAssemblyPath
  .slice(Math.max(item.value.asAssemblyPath.length - 2, 0))
  .join(' → ')

const handleSelectStatus = (option) => {
  status.value = option.value
  saveItemField(option.value.status, 'item_status', item.value.item_id)
}

const actions = computed(() => {
  if (!approval.value || Object.keys(approval.value).length === 0) return null
  if (isApprovedByApprover(approval.value)) return null
  if (isFirstStepOfApproval(approval.value)) return null
  return [
    {
      title: l('Decline'),
      icon: 'ban',
      action: handleDecline
    }
  ]
})

const fetchApproval = async () => {
  const itemId = item.value.item_id
  const userId = store.state.session.user.user_id
  const approvals = await getApprovalsByItemAndApprover({
    itemId,
    userId
  })
  if (approvals && approvals.length > 0) {
    approval.value = approvals[0]
  }
}

onMounted(() => {
  fetchApproval()
  eventBus.$on('approval-action-processed', () => {
    fetchApproval()
  })
})

onBeforeUnmount(() => {
  eventBus.$off('approval-action-processed')
})
</script>
